// css
import '../public/scss/bootstrap.scss'

import '../lib/pintura/pintura.css'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css'

import '../public/scss/addons/filepond.css'

import 'react-confirm-alert/src/react-confirm-alert.css'
import 'react-medium-image-zoom/dist/styles.css'

function MyApp({Component, pageProps}) {
  return <Component {...pageProps} />
}

export default MyApp
